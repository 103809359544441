import React from 'react';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { Navigator } from 'src/components/ScanData/Navigator';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { DateRangerFilter } from 'src/components/DateRangerFilter';
import moment from 'moment';
import { selectStores } from 'src/redux/Stores/selector';
import { useSelector } from 'react-redux';
import { FullHeightLoader } from 'src/components/FullHeightLoader';
import { fetchGraphDataServices, sendViaFTPServices } from 'src/services/DashboardServices';
import swal from 'sweetalert';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, FormHelperText } from '@mui/material';
import { RedDot } from '../components/RedDot';
import ApexChartOneLine from 'src/sections/@dashboard/app/OneLineGraph';

export default function ScanDataDashboard() {
  const { stores, isLoading } = useSelector(selectStores);

  const navigate = useNavigate();
  const { id } = useParams();

  const [datePickerRangeState, setDatePickerRangeState] = React.useState([null, null]);

  const [datePickerRangeStateGraph] = React.useState([
    {
      startDate: new Date(`01/01/${new Date().getFullYear()}`),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [spinnerGraph, setSpinnerGraph] = React.useState(true);

  const [testFile, setTestFile] = React.useState(false);

  const [dateEmptyError, setDateEmptyError] = React.useState(false);

  const [dashboardGraphData, setDashboardGraphData] = React.useState([]);

  const [applied, setApplied] = React.useState(false);

  const [sendViaFTPLoader, setSendViaFTPLoader] = React.useState(false);
  const [downloadReportLoader, setDownloadReportLoader] = React.useState(false);
  const [exportCSVLoader, setExportCSVLoader] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clearFilter = async () => {
    setApplied(false);
    setDatePickerRangeState([null, null]);
  };

  const open = Boolean(anchorEl);
  const popperId = open ? 'simple-popover' : undefined;

  const navigateTo = (url = '') => {
    navigate(`/scandata/${id}${url}`);
  };
  const fetchGraph = async (applied) => {
    try {
      setSpinnerGraph(true);
      const response = await fetchGraphDataServices(datePickerRangeStateGraph, id, applied);
      setSpinnerGraph(false);
      setDashboardGraphData(response.data?.data?.salesSubmitData || []);
    } catch (err) {
      setSpinnerGraph(false);
    }
  };
  React.useEffect(() => {
    if (id) {
      fetchGraph(false);
    }
  }, [id]);


  const downloadCSVReports = async () => {
    if (applied) {
      let filename = "";
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      setExportCSVLoader(true);
      const startDateZ = moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
      const endDateZ = moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
      const url = `${process.env.REACT_APP_WEB_BASE_URL}/reports?startDate=${startDateZ}&endDate=${endDateZ}&agencyId=${id}&delimiter=,&isTestData=false`;
      const options = {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
        },
      };
        fetch(url, options)
          .then(async(res) => {
            if (!res.ok) {
              let errorMessage = "File does not exist.";

              if (res.status === 400) {
                try {
                  const errorBody = await res.json();
                  if (errorBody && errorBody.message) {
                    errorMessage = errorBody.message;
                  }
                } catch (jsonError) {
                  console.error("Error parsing JSON from server response:", jsonError);
                }
              }

              throw new Error(errorMessage);
            }
            filename = res.headers.get('Content-Disposition').split('filename=')[1];
            return res.blob()
          })
          .then((blob) => {
            if (blob.size === 1) {
              swal('Info', 'No data found.', 'info');
              setExportCSVLoader(false);
              return;
            }
            var file = window.URL.createObjectURL(blob);
            a.href = file;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(file);
            setExportCSVLoader(false);
          }).catch((err) => {
        setExportCSVLoader(false);
            swal("Error", err?.message || "", "error");
      })
    } else {
      setDateEmptyError(true);
    }
  };

  const downloadReports = async () => {
    if (applied) {
      let filename = "";
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      setDownloadReportLoader(true);
      const startDateZ = moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
      const endDateZ = moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
      const url = `${process.env.REACT_APP_WEB_BASE_URL}/reports?startDate=${startDateZ}&endDate=${endDateZ}&agencyId=${id}&delimiter=|&isTestData=false`;
      const options = {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
        },
      };
        fetch(url, options)
          .then(async(res) => {
            if (!res.ok) {
              let errorMessage = "File does not exist.";

              if (res.status === 400) {
                try {
                  const errorBody = await res.json();
                  if (errorBody && errorBody.message) {
                    errorMessage = errorBody.message;
                  }
                } catch (jsonError) {
                  console.error("Error parsing JSON from server response:", jsonError);
                }
              }

              throw new Error(errorMessage);
            }
            filename = res.headers.get('Content-Disposition').split('filename=')[1];
            return res.blob()
          })
          .then((blob) => {
            if (blob.size === 1) {
              swal('Info', 'No data found.', 'info');
              setDownloadReportLoader(false);
              return;
            }
            var file = window.URL.createObjectURL(blob);
            a.href = file;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(file);
            setDownloadReportLoader(false);
          }).catch ((err) => {
        setDownloadReportLoader(false);
            swal("Error", err?.message || "", "error");
      })
    } else {
        setDateEmptyError(true);
    }
  };

  const sendViaFTP = async () => {
    try {
      if(applied){
      setSendViaFTPLoader(true);
        const startDateZ = moment(datePickerRangeState[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        const endDateZ = moment(datePickerRangeState[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
      const response = await sendViaFTPServices(startDateZ, endDateZ, id, applied, testFile);
      if (response.status === 200) {
        swal('Success', 'Report sent via FTP', 'success');
      }
      setSendViaFTPLoader(false);
    }
      else {
        setDateEmptyError(true);
      }
    } catch (err) {
      setSendViaFTPLoader(false);
      swal('Error', err?.response?.data?.message || 'Error occured.', 'error');
      console.log(err);
    }
  };

  return (
    <>
      <Helmet>
        <title> Scan Data | Retailz POS </title>
      </Helmet>
      {isLoading ? (
        <FullHeightLoader />
      ) : (
          <Container maxWidth="xl" style={{ margin: 'auto', marginTop: '-30px' }}>
          <Typography variant="h6">
            {stores.find((store) => store.id === id)?.Name}
          </Typography>
          <Navigator navigateTo={navigateTo} />
          <br />
          <fieldset className="add_sales_fieldSet">
            <legend className="add_sales_fieldSet_legend">Dashboard</legend>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              justifyContent={{ xs: 'center', md: 'space-between' }}
            >
              <Stack width={{ xs: '100%', md: '65%' }}>
                <fieldset className="add_sales_fieldSet">
                  <legend className="add_sales_fieldSet_legend_outlined">Graph</legend>
                  {(spinnerGraph) ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '364px' }}>
                     <RedDot />
                    </div>
                  ) : (
                    <ApexChartOneLine
                          salesData={dashboardGraphData || {
                            series: [],
                            x: []
                          }}
                    />
                  )}
                </fieldset>
              </Stack>
              <Stack width={{ xs: '100%', md: '35%' }}>
                <fieldset className="add_sales_fieldSet">
                  <legend className="add_sales_fieldSet_legend_outlined">Download Report / Manual Submit</legend>
                  <Card style={{ padding: '5px' }}>
                    <Stack direction={'column'} spacing={2}>
                      <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        width={'100%'}
                        className="apply_filter_div"
                      >
                        <DateRangerFilter
                          setApply={setApplied}
                          value={datePickerRangeState}
                          apply={applied}
                          setDateEmptyError={() => setDateEmptyError(false)}
                          setValue={setDatePickerRangeState}
                        />
                        {applied && (
                          <Button style={{ width: '20%' }} onClick={clearFilter}>
                            Clear
                          </Button>
                        )}
                      </Stack>
                        {dateEmptyError && <FormHelperText style={{ margin: '0', color : "red" }}>Date is required.</FormHelperText>}
                      {
                          stores.find((store) => store.id === id)?.Name === "RJRT" && <FormControlLabel style={{ margin: '0px', padding: '0px', fontSize: '10px !important' }} onChange={() => setTestFile(!testFile)} control={<Checkbox size='small' checked={testFile} />} label="Send Test File" />
                      }
                      <LoadingButton
                        loading={sendViaFTPLoader}
                        onClick={sendViaFTP}
                        variant="contained"
                        color="primary"
                          style={{ borderRadius: '3px', marginTop: stores.find((store) => store.id === id)?.Name === "RJRT" ? '0px' : '10px' }}
                      >
                        Submit Report Via FTP
                      </LoadingButton>
                      <LoadingButton
                        loading={downloadReportLoader}
                        variant="contained"
                        color="primary"
                        onClick={downloadReports}
                          style={{ borderRadius: '3px' }}
                      >
                        Download Report to Text
                      </LoadingButton>
                      <LoadingButton
                        loading={exportCSVLoader}
                        variant="contained"
                        color="primary"
                        onClick={downloadCSVReports}
                        style={{ borderRadius: '3px' }}
                      >
                        Export Report to CSV
                      </LoadingButton>
                    </Stack>
                  </Card>
                </fieldset>
              </Stack>
            </Stack>
          </fieldset>
        </Container>
      )}
    </>
  );
}
