import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
import '../TransitionModal/modal.css';
const style = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    width: window.innerWidth - 50,
    height: 'fit-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: 'none',
    outline: 'none',
    p: 0,
    borderRadius: '5px',
    maxWidth: '900px',
    overFlow: 'hidden',
    maxHeight: '90vh',
};

export default function XLTransitionsModal({ children, open, handleClose, title }) {
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Zoom in={open} style={{ transformOrigin: 'center' }}>
                    <Box sx={style}>
                        <div className="modal_close_with_title" onClick={() => handleClose()}>
                            <h3>{title}</h3>
                            <div className="modal_close_svg">
                                <CloseIcon />
                            </div>
                        </div>
                        <div style={{ borderBottom: '0.5px solid #ccc', width: '100%', marginBottom: '15px' }} />
                        <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                            {children}
                        </div>
                        <br />
                    </Box>
                </Zoom>
            </Modal>
        </div>
    );
}
