import React from "react";
import { TableCell, TableRow } from "@mui/material";
import { AbbreviateComponent } from "../AbbreviateComponent";
import { StatusButton } from "../StatusButton";

export const StaticTableRow = ({ row }) => {
    return (
        <TableRow style={{ display:'flex', justifyContent: 'space-evenly', backgroundColor: '#fff' }} {...row.getRowProps()}>
            {row.cells.map((cell, i) => {
                if(i === 0){
                    return <TableCell {...cell.getCellProps()} key={cell.id} style={{ display: 'flex', columnGap: '10px', minWidth: '180px', alignItems: 'center' }}>
                        <StatusButton color={row?.original?.status === 'ACTIVE' ? '#198753' : row?.original?.status === 'UPCOMING' ? '#FFC007' : '#DC3444'} />
                            <AbbreviateComponent>{row?.original?.promotionName}</AbbreviateComponent>
                        </TableCell>
                }
                else
                return (
                    <TableCell style={{
                        padding: '10px 5px 10px 5px',
                        textAlign: 'left',
                        minWidth: '165px'
                    }}
                        className="px-4 py-2" {...cell.getCellProps()} key={cell.id}>
                        {cell.render("Cell")}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};
