import React from 'react'
import { abbreviate } from 'src/utils/abbreviators'

export const AbbreviateComponent = ({children}) => {
  return (
    <span title={children} style={{ minWidth: '150px' }}> 
        {abbreviate(children, 30)}
    </span>
  )
}
