import axiosInstance from './AxiosInstance';

export const getAllStoresServices = async () => axiosInstance.get('scandata_agencies');

export const fetchItemSearchServices = async (search) => axiosInstance.get(`items?search=${search}`);

export const fetchItemFilterServices = async(body) => axiosInstance.post('items/filter', body);

export const fetchAgencyItemsServices = async (prms) => axiosInstance.post('sales-promotions/agency-and-type-list', prms)

export const fetchReportsToCSVService = async (start, end, id) => axiosInstance.get(`reports?startDate=${start}&endDate=${end}&agencyId=${id}`)