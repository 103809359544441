import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Typography, Avatar, IconButton, Popover, Badge } from '@mui/material';
import avatarLogo from '../../../assets/images/avatar_12.png';

export default function AccountPopover() {
  const user = {
    username: window.localStorage.getItem('name') !== undefined ? window.localStorage.getItem('name') : 'Admin',
    email: window.localStorage.getItem('email') !== undefined ?  window.localStorage.getItem('email') : 'admin@gmail.com'
  };
  const [open, setOpen] = useState(null);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };


  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      
      <Badge color="success" badgeContent=" " variant="dot" style={{ marginRight: '3px', marginTop: '3px' }}>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[300], 0.8),
            },
          }),
        }}
      >
        <Avatar src={avatarLogo} alt="photoURL" />
      </IconButton>
        </Badge>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.username || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email || ''}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}
