import axiosInstance from './AxiosInstance';

export const getLoggedInUserApi = (userData) => axiosInstance.post('/auth/login', userData);

export const verifyOtpUserApi = (userData) => axiosInstance.post('auth/verify-otp', userData);

export const resetPasswordUserApi = (userData) => axiosInstance.post('auth/reset-password', userData);

export const loggedOutUserApi = () =>
  axiosInstance.delete('auth/logout', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    },
  });

export const changePasswordUserApi = (userData) => axiosInstance.post('auth/change-password', userData);

export const getUsersListApi = (prms) =>
  axiosInstance.get('admin/user-list', {
    params: {
      limit: prms.limit,
      page: prms?.page || 0,
      search: prms?.search || undefined,
      sort: prms?.sort ? prms?.sort : undefined,
      order: prms?.order ? prms?.order : undefined,
    },
  });

export const userDetailApi = (userId) => axiosInstance.get(`users/${userId}/details`);
