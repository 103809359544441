import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 0 }}>
        { data?.length > 0 ? data?.map((item) => {
          return <NavItem key={item.title} item={item} />;
        }) : null}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
function NavItem({ item }) {
  const [open, setOpen] = React.useState(window.location.pathname.indexOf("scandata") !== -1);
  const { title, path, icon, info, children = [] } = item;
  if (children.length === 0) {
    return (
      <StyledNavItem
        component={RouterLink}
        id="navItem"
        to={path}
        sx={{
          '&.active': {
            color: '#A81A31 !important',
            bgcolor: 'action.selected',
            fontWeight: '600',
            fill:'#A81A31 !important',
            borderLeft: '3px solid #e1000a',
            marginLeft: '-3px',
            borderRadius: '0'
          },
        }}
      >
        <StyledNavItemIcon id='navItem_ic'>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </StyledNavItem>
    );
  } else {
    return (
      <Accordion
        style={{ width: '100%', margin: '2px 0px 2px !important' }}
        expanded={open}
      >
        <StyledNavItem component={RouterLink}
          to={path}
          id="navItem"
          sx={{
            '&.active': {
              color: '#A81A31 !important',
              bgcolor: 'action.selected',
              fontWeight: '600',
              padding: '0',
              borderLeft: '3px solid #e1000a',
              borderRadius: '0',
            },
          }}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
          >
        <AccordionSummary onClick={() => setOpen(!open)} style={{ minHeight: '20px', height: '30px', width: '100%' }} sx={{ padding: '0', margin: '0' }} aria-controls="panel1a-content" id="panel1a-header"
        >
            <StyledNavItemIcon id="navItem_ic">{icon && icon}</StyledNavItemIcon>
          <ListItemText style={{ margin: '0' }} disableTypography primary={title} />
          <ExpandMoreIcon className={`expand ${open ? 'down' : 'left'}`} />
        </AccordionSummary>
          </StyledNavItem>
        <AccordionDetails sx={{ padding: '0px 0px 0px !important', width: '95%', marginLeft: '5%' }}>
            <List disablePadding sx={{ p: 0 }}>
              {item.children?.length > 0 ? item.children?.map((item) => {
                const { title, path, icon, info, children = [] } = item;
                return <StyledNavItem
                  key={title}
                  component={RouterLink}
                  to={path}
                  id="navItem_child"
                  sx={{
                    '&.active': {
                      color: '#A81A31 !important',
                      fontWeight: '600',
                      fill: '#A81A31 !important',
                      borderRadius: '0'
                    },
                  }}
                >
                  <StyledNavItemIcon id="navItem_ic">{icon && icon}</StyledNavItemIcon>
                  <ListItemText disableTypography primary={title} />
                  {info && info}
                </StyledNavItem>;
              }) : null}
            </List>
        </AccordionDetails>
      </Accordion>
    );
  }
}
