import { Stack } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export const getColumnsEntire = (setPopUpId, msg) => {
  const deleteRow = (deleted) => {
    setPopUpId(deleted?.uuid);
  };
  return [
    {
      Header: 'SKU',
      accessor: 'sku',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.sku;
      },
    },
    {
      Header: 'Item Name',
      accessor: 'item_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.item_name || '';
      },
    },
    {
      Header: 'QTY',
      accessor: 'qty',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.qty || '-';
      },
    },
    {
      Header: 'Price',
      accessor: 'price',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.tablePrice || '-';
      },
    },
    {
      Header: 'Cost',
      accessor: 'cost',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.cost || '-';
      },
    },
    {
      Header: 'Size',
      accessor: 'size',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.size || '-';
      },
    },
    {
      Header: 'Pack',
      accessor: 'pack',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.pack || '-'; 
      },
    },
    {
      Header: 'Sale',
      accessor: 'sale',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.sale || '-';
      },
    },
    {
      Header: 'Min Purchase',
      accessor: 'min_purchase',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.min_purchase || '-'; 
      },
    },
    {
      Header: 'Limit Qty',
      accessor: 'limit_qty',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <Stack direction={'row'} justifyContent={'space-between'}>
          {row?.original?.limit_qty || '-'}
          <div onClick={() => deleteRow(row?.original)}>
            <CloseIcon color={'error'} style={{ cursor: 'pointer' }} />
          </div>
        </Stack>
      },
    },
  ];
};
