import moment from 'moment';

export const getColumns = (timezoneOffset="+0") => {
  return [
    {
      Header: 'Report Date Range',
      accessor: 'Report_Date_Range',
      disableSortBy: true,
      Cell: ({ row }) => {
        return `${moment(row?.original?.ReportStartDate).utcOffset(timezoneOffset, false).format('MMM D, YYYY')} to ${moment(
          row?.original?.ReportEndDate
        ).utcOffset(timezoneOffset, false).format('MMM D, YYYY')}`;
      },
    },
    {
      Header: 'Submitted Date',
      accessor: 'Submitted_Date',
      disableSortBy: true,
      Cell: ({ row }) => {
        return moment(row?.original?.ReportSubmitDate).utcOffset(timezoneOffset, false).format('MMM D, YYYY') || '';
      },
    },
    {
      Header: 'Submission Type',
      accessor: 'Submission_type',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.SubmissionType || '';
      },
    },
    {
      Header: 'Total Items',
      accessor: 'Total_Items',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.TotalItems || 0;
      },
    },
    {
      Header: 'Total Qty',
      accessor: 'Total_Qty',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.TotalQty || 0;
      },
    },
    {
      Header: 'Promo Price',
      accessor: 'Promo_Price',
      disableSortBy: true,
      Cell: ({ row }) => {
        return `$${Number(`${row?.original?.PromoPrice || 0}`).toFixed(2)}`;
      },
    },
    {
      Header: 'Total Sales Price',
      accessor: 'Total_Sales_Price',
      disableSortBy: true,
      Cell: ({ row }) => {},
    },
  ];
};
