export const message = {
  SUCCESS_RESPONSE: 'Success Response.',
  EVENT_DELETED: 'Event Deteled successfully.',
  LOCATION_DELETED: 'Location Deteled successfully.',
  LOCATION_SAVED: 'Location Saved successfully.',
  DETAIL_UPDATED: 'Your Details update successfully.',

  auth: {
    INVALID_CREDENTAILS: 'Invalid credentials',
    LOGOUT_SUCCESS: 'Logout successfully',
    LOGIN_SUCCESS: 'Login successfully',
    LOADING: 'Loading...',
    PASSWORD_CHANGE_SUCCESS: 'Your password has been changed successfully.',
    PASSWORD_VALIDATION: "Password must contain 6 characters including uppercase, lowercase, digits and special character."
  },
  ALL_FIELDS_REQUIRED: 'All fields are required!',
  SPACE_NOT_ALLOWED: 'White spaces are not allowed!',
  IMG_VALIDATIONS: 'The file format is not allowed.\nPlease upload the image in png, jpg, jpeg format only.',
};
