import moment from 'moment';
import { Checkbox, Stack, Button } from '@mui/material';
import "../../assets/css/TableColumns.css";
import { StatusButton } from '../StatusButton';
import { AbbreviateComponent } from '../AbbreviateComponent';
import { dateFormatter } from 'src/utils/formatTime';

export const getColumns = (changeStatusService) => {
  return [
    {
      Header: 'Promotion Name',
      accessor: 'promotion_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <StatusButton color={row?.original?.status === 'ACTIVE' ? '#198753' : row?.original?.status === 'UPCOMING' ? '#FFC007' : '#DC3444'} />
            <AbbreviateComponent>{row?.original?.promotionName}</AbbreviateComponent>
          </Stack>
        );
      },
    },
    {
      Header: 'Target Type',
      accessor: 'target_type',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.isCustomList ? 'Custom List' : row?.original?.targetType
      },
    },
    {
      Header: 'Target Name',
      accessor: 'target_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return <AbbreviateComponent>
          {row?.original?.isCustomList ? 'Item' : row?.original?.targetValue}
        </AbbreviateComponent>
      },
    },
    {
      Header: 'Start Date',
      accessor: 'start_date',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.startDate ? dateFormatter(row?.original?.startDate) : '--';
      },
    },
    {
      Header: 'End Date',
      accessor: 'end_date',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.endDate ? dateFormatter(row?.original?.endDate) : '--';
      },
    },
    {
      Header: 'Promotion Type',
      accessor: 'promotionType',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row?.original?.promotionType;
      },
    },
    {
      Header: 'Item Discount Type',
      accessor: 'discountType',
      disableSortBy: true,
      Cell: ({ row }) => {
        return 
      },
    },
  ];
};
