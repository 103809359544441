import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';

export const getColumnsSingleItem = (setPopUpId, msg) => {

    const deleteRow = (deleted) => {
        setPopUpId(deleted?.uuid);
    };

    return [
        {
            Header: 'SKU',
            accessor: 'sku',
            disableSortBy: true,
            Cell: ({ row }) => {
                return row?.original?.sku || '-';
            },
        },
        {
            Header: 'Item Name',
            accessor: 'itemName',
            disableSortBy: true,
            Cell: ({ row }) => {
                return row?.original?.itemName || '-';
            },
        },
        {
            Header: 'Quantity',
            accessor: 'quantity',
            disableSortBy: true,
            Cell: ({ row }) => {
                return row?.original?.quantity || '0';
            },
        },
        {
            Header: 'Limit Quantity',
            accessor: 'qty_limit',
            disableSortBy: true,
            Cell: ({ row }) => {
                return row?.original?.qty_limit || '0';
            },
        },
        {
            Header: 'Discount Type',
            accessor: 'bogo_discount_type',
            disableSortBy: true,
            Cell: ({ row }) => {
                return row?.original?.bogo_discount_type === 'PRICE' ? 'AMOUNT' : row?.original?.bogo_discount_type || ' ';
            },
        },
        {
            Header: 'Discount Value',
            accessor: 'bogo_discount_value',
            disableSortBy: true,
            Cell: ({ row }) => {
                return (
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        {row?.original?.bogo_discount_value || '0'}
                        <div onClick={() => deleteRow(row?.original)}>
                            <CloseIcon color={'error'} style={{ cursor: 'pointer' }} />
                        </div>
                    </Stack>
                );
            },
        },
    ];
};
