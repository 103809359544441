import { convertYMDbyHyphen } from 'src/utils/formatTime';
import axiosInstance from './AxiosInstance';

export const getDashboardDetails = async () => axiosInstance.get('admin/dashboard');

export const fetchGraphDataServices = async(date, id, applied) => {
    const localStartTime = convertYMDbyHyphen(date[0]?.startDate);
    const startDateZ = localStartTime;
    const localEndTime = convertYMDbyHyphen(date[0]?.endDate);
    const endDateZ = localEndTime;
    const url = applied ? `/reports/dashboard?agencyId=${id}&startDate=${convertYMDbyHyphen(startDateZ)}T00:00:00.000Z&endDate=${convertYMDbyHyphen(endDateZ)}T00:00:00.000Z` : `/reports/dashboard?agencyId=${id}`
    return axiosInstance.get(url);
}

export const sendViaFTPServices = async (startDate, endDate, id, applied, testFile) => {
    const url = applied ? `/reports?agencyId=${id}&startDate=${startDate}&endDate=${endDate}&sendToFtp=true&isTestData=${testFile}` : `/reports?agencyId=${id}&sendToFtp=true&isTestData=${testFile}`
    return axiosInstance.get(url);
}