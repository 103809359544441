import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components";
import { Switch, TableCell } from "@mui/material";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

export const DraggableTableRow = ({ row, id, changeStatusEvent }) => {
    const {
        attributes,
        listeners,
        transform,
        transition,
        setNodeRef,
        isDragging
    } = useSortable({
        id: row.original.id
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition
    };
    return (
        <tr id={id} ref={setNodeRef} style={style} {...row.getRowProps()}>
            {isDragging ? (
                <DraggingRow colSpan={row.cells.length}>&nbsp;</DraggingRow>
            ) : (
                <>
                        {row.cells
                            .filter((cell) => cell.column.Header !== 'Item Discount Type' && cell.column.Header !== 'Drag')
                            .map((cell) => {
                                return (
                                    <TableCell
                                    {...attributes} {...listeners}
                                        style={{
                                            padding: '10px 5px 10px 5px',
                                        }}
                                        className="px-4 py-2"
                                        key={cell.id}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render('Cell')}
                                    </TableCell>
                                );
                            })}
                        <TableCell id="table_tr_td_status">
                            <div className="status_cell_container">
                                <span>{row?.original?.discountType}</span>
                                <div id="table_tr_td">
                                    <div className="actionDiv">
                                        <Switch
                                            size="small"
                                            color="info"
                                            checked={
                                                row?.original?.status === 'ACTIVE' || row?.original?.status === 'UPCOMING'
                                            }
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => {
                                                changeStatusEvent(
                                                    row.original.id,
                                                    e.target.checked ? 'ACTIVE' : 'IN-ACTIVE'
                                                );
                                            }}
                                        />
                                        <Link
                                            className="actionButton"
                                            style={{
                                                backgroundColor: '#ddf5c4',
                                                textDecoration: 'none',
                                                margin: '0',
                                                padding: '0',
                                                marginRight: '5px',
                                            }}
                                            to={`/sales-and-promotion/edit/${row.original.id}`}
                                        >
                                            <Icon
                                                icon={'carbon:edit'}
                                                className="actionBtnIcon"
                                                style={{ color: '#5eb507' }}
                                            />
                                        </Link>
                                        {row.original.status !== 'ACTIVE' &&
                                            row.original.status !== 'UPCOMING' &&
                                            row.original.scanData === false && (
                                                <div
                                                    className="actionButton"
                                                    onClick={() => changeStatusEvent(row.original.id, 'DELETE')}
                                                    style={{ backgroundColor: '#f5bfc9' }}
                                                >
                                                    <Icon
                                                        icon={'mi:delete'}
                                                        className="actionBtnIcon"
                                                        style={{ color: 'red' }}
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>
                        </TableCell>
                </>
            )}
        </tr>
    );
};
