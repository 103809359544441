export const SET_LOGGEDIN_USER = 'SET_USER';
export const SET_LOGGEDOUT_USER = 'SET_LOGGEDOUT_USER';

export const REFRESH_STATE = 'REFRESH_STATE';

export const SET_FORGET_PASSWORD_USER_EMAIL = 'SET_FORGET_PASSWORD_USER_EMAIL';
export const RESET_FORGET_PASSWORD_USER_EMAIL = 'RESET_FORGET_PASSWORD_USER_EMAIL';

export const SET_IS_SIGNING = 'SET_IS_SIGNING';
export const RESET_IS_SIGNING = 'RESET_IS_SIGNING';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
