import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import FormHelperText from '@mui/material/FormHelperText';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { generateInvalidMsg, generateRequiredMsg } from 'src/utils/validMsgGenerator';
import { Checkbox, Radio, RadioGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormTables from '../FormTables';
import { getColumnsDiscountWithPerUnit } from '../TableColumn/ItemDiscountColumnWithDiscoutPerUnit';
import { removeExtraSpaces } from 'src/utils/validators';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomPopUp from '../TransitionModal/CustomPopUp';

export const ItemDiscount = (props) => {
  const {
    formDetails,
    formError,
    setFormDetails,
    itemDiscountRadioValue,
    setItemDiscountRadioValue,
    setFormError,
    setItemDiscountArr,
    itemDiscountArr,
    emptyTableError,
    setEmptyTableError,
  } = props;
  const [openPopup, setOpenPopUp] = React.useState(false);
  const [popUpMsg, setPopUpMsg] = React.useState({
    title: 'Alert',
    subtitle: 'Are you sure you wish to Save Promotion Details.',
    confirmBox: true
  });
  const handleChange = (e, prop) => {
    if (prop === 'discount_per_unit') {
      setFormDetails((current) => ({
        ...current,
        [prop]: e.target.checked,
      }));
    } else if (prop === 'quantity' || prop === 'qty_limit')
      setFormDetails((current) => ({
        ...current,
        [prop]: e,
      }));
    else if (prop === 'discount_value')
      setFormDetails((current) => ({
        ...current,
        [prop]: (e.target.value.indexOf(".") >= 0) ? (e.target.value.substr(0, e.target.value.indexOf(".")) + e.target.value.substr(e.target.value.indexOf("."), 3)) : e.target.value,
      }));
    else
      setFormDetails((current) => ({
        ...current,
        [prop]: e.target.value,
      }));
    if (prop === 'quantity')
      setFormError((current) => ({
        ...current,
        [prop]: e === '' || e < 0,
      }));
    else if (prop === 'qty_limit'){
      setFormError((current) => ({
        ...current,
        [prop]: e === '' || e % formDetails.quantity !== 0,
      }));
    }
    else if (prop === 'discount_value')
      setFormError((current) => ({
        ...current,
        [prop]: e.target.value === '' || e.target.value < 0,
      }));
    else
      setFormError((current) => ({
        ...current,
        [prop]: !e.target.value,
      }));
  };

  const resetForm = (leaveDefault = false) => {
    setFormError({
      discount_type: false,
      discount_value: false,
      quantity: false,
    });
    setFormDetails({
      discount_type: leaveDefault ? 'AMOUNT' : '',
      discount_value: '',
      quantity: '',
      discount_per_unit: false,
    });
  };

  React.useEffect(() => {
    setEmptyTableError(false);
    setItemDiscountArr([]);
    resetForm(false);
  }, []);

  const onSubmitDiscount = () => {
    const { discount_type, discount_value, quantity, qty_limit } = formDetails;
    if (!removeExtraSpaces(discount_type) || discount_value <= 0 || quantity <= 0 || (qty_limit ? (qty_limit%quantity !== 0) : false)) {
      setFormError({
        discount_type: !removeExtraSpaces(discount_type),
        discount_value: discount_value === '' || discount_value <= 0,
        quantity: quantity === '' || quantity <= 0,
        discount_per_unit: false,
        qty_limit: !qty_limit ? false : (qty_limit % quantity !== 0)
      });
    } 
    else if (itemDiscountArr.length > 0 && itemDiscountArr[0].discount_type !== discount_type) {
      setPopUpMsg({
        title: 'Alert',
        subtitle: 'Different value for discount type cannot be entered.',
        confirmBox: false
      });
      setOpenPopUp(true);
      return;
    }
    else {
      setEmptyTableError(false);
      for(let i = 0 ; i<itemDiscountArr.length; i+=1){
        const item = itemDiscountArr[i];
        if(item.discount_value === discount_value && item.quantity === quantity && item.discount_type === discount_type){
          setPopUpMsg({
            title: 'Alert',
            subtitle: 'Same value cannot be entered.',
            confirmBox: false
          });
          setOpenPopUp(true);

            return;
        }
      }
      if (discount_type === "PERCENTAGE") {
        if (discount_value < 1 || discount_value > 100)
          return;
      }
      const obj = {
        uuid: uuidv4(),
        discount_type,
        discount_value,
        quantity,
        qty_limit: qty_limit,
        discount_per_unit: formDetails.discount_per_unit.toString(),
      };
      setItemDiscountArr((currentItem) => [...currentItem, obj]);
      setFormError({
        discount_type: false,
        discount_value: false,
        quantity: false,
      });
      setFormDetails({
        ...formDetails,
        discount_value: '',
        qty_limit: '',
        quantity: '',
        discount_per_unit: false,
      });
    }
  };


  React.useEffect(() => {
    if (itemDiscountArr.length > 0) {
      setFormDetails({
        ...formDetails,
        discount_type: itemDiscountArr[0].discount_type
      })
    }
  }, [itemDiscountArr]);


  return (
    <Stack direction={'column'} width={'100%'} spacing={2} alignItems={'center'}>
      <Stack direction={{ xs: 'column', sm: 'row' }} width={'100%'} spacing={2} alignItems={'baseline'}>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onClick={(event) => {
              const clickedValue = Number(event.target.value);
              if(clickedValue >= 0 && clickedValue <= 9){
                setFormDetails((current) => ({
                  ...current,
                  discount_value: '',
                  discount_type: '',
                  qty_limit: '',
                  quantity: '',
                  discount_per_unit: false,
                }));
              setItemDiscountRadioValue(
                itemDiscountRadioValue === clickedValue ? null : clickedValue
              );
              }
            }}
          >
            <FormControlLabel
              checked={itemDiscountRadioValue === 0}
              value={0}
              control={<Radio />}
              label="ScanData"
              size="small"
              disabled={itemDiscountArr.length > 0}
            />
            <FormControlLabel
              checked={itemDiscountRadioValue === 1}
              value={1}
              control={<Radio />}
              label="Coupon"
              size="small"
              disabled={true}
            />
            <FormControlLabel
              checked={itemDiscountRadioValue === 2}
              value={2}
              control={<Radio />}
              label="Retailer Funded"
              size="small"
              disabled={itemDiscountArr.length > 0}
            />
            <FormControlLabel
              checked={itemDiscountRadioValue === 3}
              value={3}
              control={<Radio />}
              label="Other Manufacture"
              size="small"
              disabled={itemDiscountArr.length > 0}
            />
            <FormControlLabel
              checked={itemDiscountRadioValue === 4}
              value={4}
              control={<Radio />}
              label="Manufacture"
              size="small"
            disabled={itemDiscountArr.length > 0}
            />
            <FormControlLabel
              checked={itemDiscountRadioValue === 5}
              value={5}
              control={<Radio />}
              label="Loyalty"
              size="small"
            disabled={itemDiscountArr.length > 0}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} width={'100%'} spacing={2} alignItems={'baseline'}>
        <FormControl color="primary" error={formError.discount_type} style={{ width: '100%' }}>
          <InputLabel id="demo-multiple-checkbox-label" size="small">
            Select Discount Type
          </InputLabel>
          <Select
            value={formDetails.discount_type}
            label="Select Discount Type"
            size="small"
            disabled={itemDiscountArr.length > 0}
            error={formError.discount_type}
            onChange={(e) => handleChange(e, 'discount_type')}
          >
            <MenuItem value={'AMOUNTOFF'}>Amount Off</MenuItem>
            {itemDiscountRadioValue !== 5 &&
            <MenuItem value={'PRICE'}>Amount</MenuItem>}
            {itemDiscountRadioValue !== 5 &&
            <MenuItem value={'PERCENTAGE'}>Percentage</MenuItem>}
          </Select>
          {formError.discount_type && <FormHelperText>{generateRequiredMsg('Discount Type')}</FormHelperText>}
        </FormControl>
        <TextField
          color="primary"
          label={`${formDetails?.discount_type === "PERCENTAGE" ? "Value (%)" : "Value ($)"} * `}
          size="small"
          type={'number'}
          value={formDetails?.discount_value || ''}
          style={{ width: '60%' }}
          onChange={(e) => handleChange(e, 'discount_value')}
          error={formError.discount_value || ((formDetails.discount_type === "PERCENTAGE" && formDetails.discount_value !== '') ? Number(formDetails.discount_value) < 1 || Number(formDetails.discount_value) > 100 : false)}
          helperText={
            (formError.discount_value || ((formDetails.discount_type === "PERCENTAGE" && formDetails.discount_value !== '') ? Number(formDetails.discount_value) < 1 || Number(formDetails.discount_value) > 100 : false))
              ? formDetails.discount_value === ''
                ? generateRequiredMsg('Value')
                : generateInvalidMsg('Value')
              : null
          }
        />
        <TextField
          color="primary"
          label="Quantity * "
          size="small"
          value={formDetails?.quantity || ''}
          style={{ width: '60%' }}
          onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'quantity')}
          error={formError.quantity}
          helperText={
            formError.quantity
              ? formDetails.quantity === ''
                ? generateRequiredMsg('Quantity')
                : generateInvalidMsg('Quantity')
              : null
          }
        />
        <TextField
          color="primary"
          label="Qty Limit"
          size="small"
          value={formDetails?.qty_limit || ''}
          style={{ width: '100%' }}
          onChange={(e) => handleChange(Number(e.target.value.replace(/\D/g, '')), 'qty_limit')}
          error={formError.qty_limit}
          helperText={'Limit Quantity Must Be Multiplier of Quantity!'}
        />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'flex-start', sm: 'center' }}
          alignItems={'center'}
          width={'100%'}
        >
          <FormControlLabel
            onChange={(e) => handleChange(e, 'discount_per_unit')}
            control={<Checkbox checked={formDetails.discount_per_unit} />}
            label="Discount per unit"
          />
          <Tooltip title={<p style={{ fontSize: '14px' }}>The amount per unit for example: If you buy 10 books at $10 each, but get a 10% discount per unit, you will pay $9 for each book. </p>} arrow>
          <InfoOutlinedIcon color="secondary" style={{ cursor: 'pointer' }} />
          </Tooltip>
        </Stack>
      </Stack>
      <div className="save_button_div">
        <LoadingButton
          size="small"
          type="button"
          onClick={onSubmitDiscount}
          variant="contained"
          color="primary"
          style={{ color: 'white', borderRadius: '3px' }}
        >
          <AddCircleOutlineOutlinedIcon style={{ marginRight: '10px' }} /> Add
        </LoadingButton>
      </div>
      <CustomPopUp
        open={openPopup}
        handleClose={() => setOpenPopUp(false)}
        title={popUpMsg.title}
        subtitle={popUpMsg.subtitle}
      >
        <Stack direction="row" justifyContent={'end'}>
          <Button variant="contained" style={{ backgroundColor: '#A81A31', color: '#fff', borderRadius: '3px' }} onClick={() => setOpenPopUp(false)}>
            OK
          </Button>
        </Stack>
      </CustomPopUp>
      <Stack width={'100%'}>
        <FormTables events={itemDiscountArr} isLoading={false} totalPage={1}
          msg={"Item discount"}
          getColumn={getColumnsDiscountWithPerUnit} setArr={setItemDiscountArr} />
        {emptyTableError && (
          <FormHelperText style={{ color: 'red' }}>Table is empty ! Please add items.</FormHelperText>
        )}
      </Stack>
    </Stack>
  );
};
