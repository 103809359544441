import { Stack, Typography, FormControl, Select, MenuItem } from '@mui/material';
import React from 'react';
import { Input } from 'reactstrap';

const CustomPage = ({ goToNext, pageCount, pageIndex }) => {
  const pageLimit = isNaN(pageCount) ? 1 : pageCount;
  return (
    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} pl={2}>
      <Typography
        variant="span"
        style={{
          backgroundColor: '#EEEBEB',
          fontSize: '12px',
          padding: '6px 5px 6px',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
          fontWeight: '700',
          border: '1px solid #ccc',
        }}
      >
        Page:
      </Typography>
      <FormControl size="small">
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={pageIndex}
          onChange={(e) => {
            goToNext(Number(e.target.value) - 1);
          }}
          sx={{
            ':hover': {
              border: 'none',
            },
            '.MuiSelect-icon': {
              right: 0,
              color: 'black',
            },
              '.css-1gug4hx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1gug4hx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1gug4hx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                  paddingRight: '20px'
              },
              '.css-1gug4hx-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                  padding: '4.5px 4px'
              }
          }}
          style={{
            padding: '5.5px 6px !important',
            height: '30px',
            borderRadius: '5px',
            outline: '1.5px solid black',
            marginRight: '2px',
            fontSize: '13px',
            fontWeight: '600',
            marginLeft: '-3px',
            backgroundColor: '#fff',
          }}
          autoWidth
        >
          {Array.from(Array(pageLimit).keys()).map((page, index) => (
            <MenuItem
              style={{
                fontSize: '13px',
              }}
              key={index + 1}
              value={index + 1}
            >
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default CustomPage;
