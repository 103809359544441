import { DELETE_EVENT, FETCH_EVENTS_FAILURE, FETCH_EVENTS_REQUEST, FETCH_EVENTS_SUCCESS } from './type';

const initialState = {
  token: null,
  isLoading: false,
  error: '',
  events: [],
  selectedEvent: null,
  totalPage: 0,
  count: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        totalPage: 0,
        count: 0
      };
    case FETCH_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        events: action.payload.events,
        selectedEvent: null,
        totalPage: action.payload.totalpage,
        error: '',
        count: action.payload.count
      };
    case FETCH_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        events: [],
        selectedEvent: null,
        totalPage: 0,
        error: action.payload,
        count: 0
      };
    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((i) => i.id !== action.payload.id),
      };

    default:
      return state;
  }
};

export default reducer;
