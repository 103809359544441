export function capitalizeFirstLetter(string) {
    return  string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}


export function abbreviate(s, length) {
    return `${s?.slice(0, length)} ${s?.length > length ? '...' : ''}`;
}

export function privacyAbbreviation(s, length) {
    const indexofAt = s.indexOf('@');
    const beginOfAbbreviation = Math.abs(indexofAt - length);
    let stars = "";
    for (let i = beginOfAbbreviation; i < indexofAt; i+=1)
        stars += "*";
    return `${s?.slice(0, beginOfAbbreviation)}${stars}${s?.slice(indexofAt, s.length)}`;
}

export function renderAvatarTitle(firstName, lastName, email) {
    let str = ""
    if (firstName && lastName) {
        str = `${firstName[0]}${lastName[0]}`;
    }
    else if (firstName && !lastName) {
        str  = `${firstName[0]}`;
    }
    else {
        str  = `${email[0].toUpperCase()}`;
    }
    return str;
}


export const roundNumberTwo = (num) => Math.round(Number(num)*100)/100;