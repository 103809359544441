import axiosInstance from './AxiosInstance';

export const setEventListSequenceApi = (prms) =>
   axiosInstance.put('/sales-promotions/sequence/update', {
    promotions: prms,
  });

export const getSalesAndPromotionListApi = (prms) => {
  const params = {
    limit: prms?.limit,
    page: prms?.page + 1|| 1,
    search: prms?.search || undefined,
    sort: prms?.sort ? prms?.sort : undefined,
    order: prms?.order ? prms?.order : undefined,
  };
  if (prms.status !== '') {
    params.status = prms?.status;
  }
  if(prms.type !== '')
   params.type = prms.type

  if (prms?.endDate !== '')
    params.endDate = prms?.endDate

  if (prms?.startDate !== '')
    params.startDate = prms?.startDate

  if (prms?.type !== '')
    params.type = prms?.type

  if (prms?.typeName !== '')
    params.typeName = prms?.typeName

  if (prms?.promo !== '')
    params.promo = prms?.promo

  if (prms?.discount !== '')
    params.discount = prms?.discount
    
  return axiosInstance.get('/sales-promotions', {
    params,
  });
};

export const changeStatusService = (status, rows) =>
  axiosInstance.post('/sales-promotions/update-promotion-status', {
    promotionIds: [Number(rows)],
    status: status,
  });

export const fetchTargetServices = (type) => axiosInstance.post('/sales-promotions/promotion-type', { type: type });

export const fetchScanDataCompanyServices = () => axiosInstance.get('/stores');

export const createSalesAndPromotionsServices = (body) => axiosInstance.post('/sales-promotions', body);
export const editSalesAndPromotionsServices = (id, body) => axiosInstance.put(`/sales-promotions/${id}`, body);


export const fetchSalesAndPromotionsByIdServices = (id) => axiosInstance.get(`/sales-promotions/${id}`);