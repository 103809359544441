export const MESSAGES = {
    INVALID_EMAIL: 'Please enter valid email.',
    INVALID_PASSWORD: 'Password must be at least 8 characters long and must contain 1 uppercase, 1 lowercase, 1 special character and 1 digit.',
    EMAIL_REQUIRED: 'Please enter email.',
    ORGANIZER_REQUIRED: 'Please select an organizer.',
    PASSWORD_REQUIRED: 'Please enter password.',
    CONFIRM_PASSWORD_REQUIRED: 'Please enter confirm password.',
    INVALID_CONFIRM_PASSWORD: 'Password and confirm password should be same.',
    FULLNAME_REQUIRED: 'Please enter full name.',
    ENTITY_NAME_REQUIRED: 'Please enter entity name.',
    FIRSTNAME_REQUIRED: 'Please enter first name.',
    LASTNAME_REQUIRED: 'Please enter last name.',
    ADDRESS_LINE1_REQUIRED: 'Please enter address.',
    PHONE_REQUIRED: 'Please enter phone.',
    INVALID_PHONE: 'Please enter valid phone.',
    WEBSITE_REQUIRED: 'Please enter website.',
    EVENT_REQUIRED: 'Please enter name.',
    VENUE_REQUIRED: 'Please enter venue.',
    CITY_REQUIRED: 'Please enter city.',
    STATE_REQUIRED: 'Please enter state.',
    COUNTRY_REQUIRED: 'Please enter country.',
    STREET_REQUIRED: 'Please enter street.',
    LAT_REQUIRED: 'Please enter latitude.',
    LNG_REQUIRED: 'Please enter longitude.',
    POSTCODE_REQUIRED: 'Please enter postcode.',
    STARTDATE_REQUIRED: 'Please enter start date.',
    STARTTIME_REQUIRED: 'Please enter start time.',
    ENDTIME_REQUIRED: 'Please enter end time.',
    ERROR_ENDTIME: 'End time should be greater than start time.',
    ERROR_ENDDATE_REQUIRED: 'End date should be greater than start date.',
    FORMATDESC_REQUIRED: 'Please enter format description.',
    FORMATURL_REQUIRED: 'Please enter format file.',
    RULEDESC_REQUIRED: 'Please enter rule description.',
    RULEURL_REQUIRED: 'Please enter rule file.',
    NO_OF_GAMES_REQUIRED: 'Please enter no of games.',
    REFREE_REQUIRED: 'Please enter referee.',
    AGE_LIMIT_REQUIRED: 'Please enter age limit.',
    TEAM_PRICE_REQUIRED: 'Please enter team price.',
    INDIVIDUAL_PRICE_REQUIRED: 'Please enter individual price.',
    LESS_TEAM_PRICE_REQUIRED: 'Team price not less than individual price.',
    MORE_INDIVIDUAL_PRICE_REQUIRED: 'Individual price not greater than team price.',
    REGISTRATION_LINK_REQUIRED: 'Please enter registration link.',
    GENDER_REQUIRED: 'Please enter gender.',
    SLIDER_IMAGES_REQUIRED: 'Please enter slider images.',
    AMENITY_SELECTION_REQUIRED: 'Please enter amenity selection.',
    TIMEZONE_REQUIRED: 'Please enter timezone.',
    EVENT_TYPE_REQUIRED: 'Please enter type.',
    RESEND_OTP_SUCCESS: "OTP resent successfully.",
    SOMETHING_WENT_WRONG: "Something went wrong.",
    BROADCAST_TITLE_REQUIRED: "Please enter a broadcast title.",
    BROADCAST_DESC_REQUIRED: "Please enter a broadcast description.",
    PRICE_NAME_REQUIRED: "Please enter Price name.",
    URL_REQUIRED: "Please enter entity website.",
    DESC_REQUIRED: "Please enter entity description.",
    VENUE_DESC: "Please enter venue description.",
    VENUE_NAME: "Please enter venue name.",
    AMENITIES_REQUIRED: "Please select amenities selection.",
    EMPTY_PRICE_ARRAY_WARNING: "Please enter atleast one price level.",
    DAYS_OF_WEEKS_REQUIRED: "Please select days.",
    SPORT_TYPE_REQUIRED: "Please select a sport type.",
    VIDEO_INSTRUCTIONS: "*Please upload video/image of dimensions 1988x500.",
    IMAGES_REQUIRED: "Please upload atleast one image.",
    DELETE_VIDEO_MSG_COMFIRMATION: "Once deleted you can't retrieve the video.",
    DELETE_LOCAL_VIDEO_MSG_COMFIRMATION: "Do you want to remove this video.",
    IMG_VALIDATIONS: "The file format is not allowed.\nPlease upload the image in png, jpg, jpeg, webp format only.",
    BROADCST_ORGANIZER_HELPERTEXT: "You can send the message to a particular organizer."
}