import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// eslint-disable-next-line react/display-name
const Page = forwardRef(({ children, title = '', disabled = false, goBack = true, meta, ...other }, ref) => {
  const navigate = useNavigate();
  return <>
    <Helmet>
      <title>{`${title} | Retailz POS`}</title>
      {meta}
    </Helmet>
    {goBack && <Stack direction="row" alignItems="center" mb={2} mt={-3} display={window.innerWidth > 500 ? "flex" : "none"}>
      <Button disabled={disabled} onClick={() => navigate(-1)}><ArrowBackIcon /></Button>
          <Typography variant="h6">
            {title}
          </Typography>
    </Stack>}
    <Box ref={ref} {...other}>
      {children}
    </Box>
  </>
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
  goBack: PropTypes.any,
  disabled: PropTypes.any
};

export default Page;
